<template>
<job-posting-layout>
  <template #topContent>
    <loader :loading="loading" v-if="loading"/>
    <div class="stepContent tw-w-full" v-if="!loading">
      <step-count :count="5"/>
      <step-title title="Where is this job located?" />
      <div class=" tw-flex tw-w-full  tw-flex-col-reverse lg:tw-flex-row">
        <div class="lg:tw-mt-12 lg:tw-w-11/12">
          <small v-if="showError" style="color: red">{{showError}}</small>
        <div class="tw-flex-col">
          <validation-observer ref="observer" v-slot="{handleSubmit}" >
            <form @submit.prevent="handleSubmit(updateJobById)">
              <div class="tw-pt-10">
                <label class="label">Country</label>
                <div class="">
                  <validation-provider
                      v-slot="{ errors }"
                      rules="required"
                      name="country">
                    <v-select @click="clearStateAndCity"
                      v-model="selectedLocation.country"
                      :items="sortData"
                              item-text="name"
                      return-object
                              @change="callState"
                      placeholder="Select Country"
                      solo hide-details
                      class="select-input lg:tw-w-80 "
                    ></v-select>
                    <span class="span" v-show="errors">{{ errors[0] }}</span>
                  </validation-provider>
                </div>
              </div>
              <div class="lg:tw-pt-10 tw-flex-col lg:tw-flex-row tw-flex tw-items-center">
                <div class="lg:tw-mr-20 tw-w-full lg:tw-w-80 tw-pt-5">
                  <label class="label">State</label>
                  <div class="">
                    <validation-provider
                        v-slot="{ errors }"
                        rules="required"
                        name="state">
                      <v-combobox
                          v-model="selectedLocation.state"
                          :items="states"
                          item-text="name"
                          placeholder="Select State"
                          solo hide-details
                          class="select-input lg:tw-w-80 "
                      ></v-combobox>
                      <span class="span" v-show="errors">{{ errors[0] }}</span>
                    </validation-provider>
                  </div>
                </div>
                <div class="tw-w-full tw-pt-5">
                  <label class="label ">City</label>
                  <div class="">
                    <validation-provider
                        v-slot="{ errors }"
                        name="city">
                      <v-combobox v-if="selectedLocation.country === 'Nigeria'"
                          v-model="selectedLocation.city"
                          :items="getLga"
                          placeholder="Select City"
                          solo hide-details
                          class="select-input lg:tw-w-80 "
                      ></v-combobox>
                      <v-text-field v-if="selectedLocation.country !== 'Nigeria'"
                                  v-model="selectedLocation.city"
                                  placeholder="Enter City Name"
                                  solo hide-details
                                  class="select-input lg:tw-w-80 "
                      ></v-text-field>
                      <span class="span" v-show="errors">{{ errors[0] }}</span>
                    </validation-provider>

                  </div>
                </div>
              </div>
              <div class="lg:tw-pt-10 tw-flex tw-items-center">
                <v-checkbox v-model="remote"
                            class="select-input"
                            color="#8F47F4">
                  <template #label>
                    <label class="label tw-cursor-pointer">Remote</label>
                  </template>
                </v-checkbox>

              </div>
            </form>
          </validation-observer>
        </div>
    </div>


      </div>
    </div>
  </template>

  <template #bottomNav>
    <bottom-navbar  @click:back="routeBack" :button-name="$route.query.edit?'Save ':'Next '" @click:next="updateJobById"
                    :previous="!$route.query.edit"  :loading="buttonLoading" />

  </template>
</job-posting-layout>
</template>

<script>
import JobPostingLayout from "../layouts/JobPostingLayout";
import BottomNavbar from "../reuseables/BottomNavbar";
import StepCount from "../reuseables/StepCount";
import StepTitle from "../reuseables/StepTitle";
import LGA from "../../../assets/lga";
import { getJobById, updateJobById, getCountries, getStates} from "../../../services/api/APIService"
import Loader from "../../UIComponents/reusablesIcon/Loader";
// import SuggestionView from "./SuggestionView";
export default {
  name: "StepFive",
  components: { Loader, StepTitle, StepCount, BottomNavbar, JobPostingLayout},
  data(){
    return{
      countries:[],
      states : [],
      cities:[],
      getState : true,
      selectedLocation:{
        country:"",
        state : "",
        city : "",
      },
      remote : false,
      loading : false,
      showError: "",
      buttonLoading : false
    }
  },
  methods:{
    routeBack(){
        this.$router.push({name: 'Job-stepFour'})
    },
    populatedLocation(jobId){
      this.loading = true
      let data = {}
      data.id = jobId
      data.site_name = this.$siteName()
      getJobById(data).then(res =>{
          this.selectedLocation.country = res.data.country
          this.selectedLocation.state = res.data.state
          this.selectedLocation.city = res.data.city
          this.remote = res.data.is_remote

        this.loading = false
      })
          .catch(err =>{
            console.log(err.response.data.detail)
            this.loading = false
            this.$displaySnackbar({message:err.response.data.detail, success:false})
          })
    },
    updateJobById(){
      this.buttonLoading = true
      let job = JSON.parse(sessionStorage.getItem('returnedSelectedDetails'))
      let jobId = job.id
      let locationData = {}
      if (this.selectedLocation.state === ""){
        this.showError="State can not be empty"
        this.buttonLoading = false
      }
      else {
        this.showError=""
        locationData.country = this.selectedLocation.country.name
        locationData.state = this.selectedLocation.state
        locationData.city = this.selectedLocation.city
        locationData.is_remote = this.remote
        locationData.site_name = this.$siteName()
        updateJobById(jobId,locationData).then(() => {
          this.buttonLoading = false
          if(this.$route.query.edit){
            this.$router.push({name:'JobSummary'})
          }
          else {
            this.$router.push({name:'Job-stepSix'})
          }
        })
            .catch(err =>{
              this.buttonLoading = false
              console.log(err.response.data.detail)
              this.$displaySnackbar({message:err.response.data.detail, success:false})
            })
      }
    },
    allCountries(){
      getCountries().then(res => {
        const data = JSON.parse(JSON.stringify(res.data))
        this.countries = data
      }).catch(err =>{
        this.$displaySnackbar({message:err, success:false})
      })
    },
    callState(val){
      getStates(val.iso2).then(res => {
        const data = JSON.parse(JSON.stringify(res.data))
        console.log(data)
        this.states = data.map(country => country.name).sort()

      }).catch(err =>{
        this.$displaySnackbar({message:err, success:false})
      })
    },
    clearStateAndCity(){
      this.selectedLocation.state =""
      this.selectedLocation.city = ""
    }
  },
  computed:{
    logLocation(){
     return  console.log(this.selectedLocation)
    },
    sortData(){
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      return this.countries.sort((a, b) => a.name.localeCompare(b.name))
    },
    getLga() {
      return LGA[this.selectedLocation.state] || []
    },
  },
  created() {
    let job = JSON.parse(sessionStorage.getItem('returnedSelectedDetails'))
    let jobId = job.id
    this.populatedLocation(jobId)
    this.allCountries()

  }

}
</script>

<style scoped lang="scss">
.span {
  font-family: DM Sans !important;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #FF2E2E;
}

::v-deep .v-text-field fieldset, .v-text-field .v-input__control {
  color: #9CABB5 !important;
}
</style>